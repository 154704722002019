import { CreatorReducer } from '../base/base';
import { RootState } from '../reducer';
import { IDeliveryActions, IDeliveryState } from './deliveryTypes';

const init: IDeliveryState = {
  zipCode: '',
  isInCourierArea: null,
};

const creator = new CreatorReducer<IDeliveryActions, IDeliveryState>('delivery');
creator.addAction('setZipCode', (state, action) => {
  return { ...state, zipCode: action.payload };
});
creator.addAction('setIsInCourierArea', (state, action) => {
  return { ...state, isInCourierArea: action.payload };
});

const actionsDelivery = creator.createActions();

const selectorsDelivery = {
  getZipCode: (state: RootState) => state.delivery.zipCode?.trim() ?? null,
  isInCourierArea: (state: RootState) => state.delivery.isInCourierArea,
};

export { actionsDelivery, selectorsDelivery };

export default creator.createReducer(init);
