import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actionsCart, selectorsCart } from '../../redux/cart/cartReducer';
import HeaderCart from '../Cart/HeaderCart';
import { selectorsUser } from '../../redux/user/userReducer';
import { useLessThen991 } from '../../utils/mediaQuery';
import { useFormattingContext } from '../../context/FormattingContext';
import { usePaths } from '../Routes/RouterList';
import { actionsOther, selectorsOther } from '../../redux/other/otherReducer';
import { JsonLd } from 'react-schemaorg';
import AssortmentNoticeModal from '../Modals/AssortmentNoticeModal';
import { selectorsOrder } from '../../redux/order/orderReducer';
import HeaderLogo from './HeaderLogo';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import HeaderBlockInfo from './HeaderBlockInfo';
import CategoriesList from './CategoriesList';
import OwnCategoriesList from './OwnCategoriesList';
import service from '../../services/service';
import SearchedItem from '../Products/SearchedItem';
import SystemMessage from '../Modals/SystemMessage';
import searchIcon from '../../assets/images/ic-close_m.svg';
import { removeFromLocaleStorage } from '../../utils/localStorageHelper';
import { coupon } from '../../constants/payData';
import NonAuthModal from '../Modals/NonAuthModal';
import ClipLoader from 'react-spinners/ClipLoader';
import { blockBodyScroll } from '../../utils/blockBodyScroll';
import { IProduct } from '../../typings/IProduct';
import { ITag } from '../../typings/ITag';
import LoginButton from './LoginButton';
import useGenerateSchemaOrgJson from '../../useHooks/useGenerateSchemaOrgJson';
import { setLoaderColor } from '../../utils/setLoaderColor';
import { actionsFilters } from '../../redux/filters/filtersReducer';
import { selectorsDelivery } from '../../redux/delivery/deliveryReducer';
import { useNavigate } from 'react-router';
import getShopLogo from '../../utils/getShopLogo';
import { searchingTags } from '../../constants/searchingTags';
import ProductLoader from '../Loaders/ProductLoader';
import { selectorsCategory } from '../../redux/category/categoryReducer';
import { ICustomCategory } from '../../typings/ICategory';
import { CustomCategorySearch } from '../../typings/CustomCategorySearch';
import { useRouterStaticContext } from '../../context/RouterStaticContext';

type SearchingResult = {
  customCategories: CustomCategorySearch[];
  items: IProduct[];
  productGroups: { id: number; name: string }[];
  count: number;
};

const HeaderComponent = React.memo((props: any) => {
  const { sum } = props;
  const paths = usePaths();
  const TOP_VALUE_FOR_SEARCH = 10;
  const TAGS_LIMIT_FOR_ROW = 6;
  const ADVANCED_SEARCH_MIN_LENGTH = 2;

  const schema = useGenerateSchemaOrgJson();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLessThen991 = useLessThen991(isDesktop);
  const { formatPrice } = useFormattingContext();
  const currentZipCode = useSelector(selectorsDelivery.getZipCode);

  const user = useSelector(selectorsUser.getUser);
  const cart = useSelector(selectorsCart.getCart);
  const count = useSelector(selectorsCart.getGeneralCount);
  const isOpenCart = useSelector(selectorsCart.getIsOpenCart);
  const hideHeader = useSelector(selectorsOther.getHideHeader);
  const isNonAuthOpen = useSelector(selectorsOther.getNonAuth);
  const isLoadingData = useSelector(selectorsCart.isLoadingData);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const isExpressDelivery = useSelector(selectorsOrder.isDeliveryExpress);
  const persistedSearchField = useSelector(selectorsOther.getProductsSearchQuery);
  const isProductSearchPopupOpen = useSelector(selectorsOther.getProductSearchPopupOpenState);
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const availableTreeCategories = useSelector(selectorsCategory.getCategories);

  const [showTags, setShowTags] = useState(true);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [productsCount, setProductsCount] = useState<number>(0);
  const [tags, setTags] = useState<ITag[]>([]);
  const [categories, setCategories] = useState<CustomCategorySearch[]>([]);
  const [isHasMore, setIsHasMore] = useState(true);
  const [searchField, setSearchField] = useState('');
  const [trimmedSearchField, setTrimmedSearchField] = useState('');
  const [isOpenClearCart, setIsOpenClearCart] = useState(false);
  const [isOpenFullSearch, setIsOpenFullSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllTagsOpened, setIsAllTagsOpened] = useState(false);
  const staticContext = useRouterStaticContext();

  if (staticContext) {
    if (!staticContext.headers) {
      staticContext.headers = { link: [] };
    }
    staticContext.headers.link.push({
      rel: 'preload',
      href: getShopLogo(!isDesktop),
      as: 'image',
    });
  }

  useEffect(() => {
    if (cart.total === 0) {
      removeFromLocaleStorage(coupon);
    }
  }, [cart]);

  useEffect(() => {
    setSearchField(persistedSearchField);
    setTrimmedSearchField(persistedSearchField.trim());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistedSearchField]);

  useEffect(() => {
    dispatch(actionsOther.productsSearchingTotal(products.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  useEffect(() => {
    if (searchField && location && !location.pathname.includes('all_products')) {
      resetProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const toggleCart = () => {
    if (sum > 0) dispatch(actionsCart.toggleCart(!isOpenCart));
  };

  const handleClearCart = () => {
    dispatch(actionsCart.clear());
    setIsOpenClearCart(false);

    dispatch(
      actionsOther.setData({
        isOpenDeliveryType: true,
      }),
    );
  };

  const handleCloseClearCart = () => {
    setIsOpenClearCart(false);
  };

  const closeCart = () => {
    blockBodyScroll(false);
    dispatch(actionsCart.toggleCart(false));
  };

  const getParentCategory = (childCategoryId?: number) => {
    if (!childCategoryId) {
      return null;
    }
    if (!availableTreeCategories[childCategoryId]) {
      return null;
    }
    let treeForChildCategory = [availableTreeCategories[childCategoryId]];
    let parent = treeForChildCategory[0].parent;

    while (parent !== null && parent !== undefined) {
      const category = availableTreeCategories[parent.id];
      if (!category) {
        return null;
      }
      treeForChildCategory.unshift(category);
      parent = category.parent;
    }

    if (treeForChildCategory?.length > 1) {
      treeForChildCategory = treeForChildCategory.filter(
        (category: ICustomCategory) => category.id !== childCategoryId,
      );
    }
    return treeForChildCategory.pop();
  };

  const extendSameNamesWithParentCategory = (categories: CustomCategorySearch[], duplicates: string[]) => {
    for (const category of categories) {
      if (!duplicates.includes(category.name)) {
        continue;
      }
      const parentCategory = getParentCategory(category.id);
      if (!parentCategory) {
        continue;
      }
      category.parentName = parentCategory.name;
    }
  };

  const tryToExtendSameNamesWithParentCategory = (categories: CustomCategorySearch[]) => {
    const currentCategoryNames = categories.map((category: CustomCategorySearch) => category.name);
    const duplicates = currentCategoryNames.filter(
      (itemName, index) => currentCategoryNames.indexOf(itemName) !== index,
    );
    if (duplicates?.length) {
      extendSameNamesWithParentCategory(categories, duplicates);
    }

    setCategories((prevCategories) => [...prevCategories, ...categories]);
  };

  const searchProducts = (signal: AbortSignal, text: string) => {
    if (isLessThen991) {
      return;
    }
    if (!text?.length) {
      setShowTags(true);
    }
    if (text.length < ADVANCED_SEARCH_MIN_LENGTH) {
      return;
    }
    setIsAllTagsOpened(false);
    service
      .searchProductsAdvanced(signal, text, TOP_VALUE_FOR_SEARCH, 0, isInCourierArea)
      .then((res: any) => {
        if (signal.aborted) {
          return;
        }
        setShowTags(false);

        const data = res?.data || ({} as SearchingResult);
        const tags = data?.productGroups?.length ? data.productGroups : [];
        const products = data?.items?.length ? data.items : [];
        const categories = data?.customCategories?.length ? data.customCategories : [];
        const productsCount = data?.count || 0;
        if (Number(data?.count) < TOP_VALUE_FOR_SEARCH) {
          setIsHasMore(false);
        }
        setProductsCount(productsCount);
        setTags((prevTags) => [...prevTags, ...tags]);
        setProducts((prevProducts) => [...prevProducts, ...products]);
        tryToExtendSameNamesWithParentCategory(categories);
      })
      .catch((e: any) => console.error('ERROR'));
  };

  useEffect(() => {
    setProducts([]);
    setTags([]);
    setCategories([]);
    dispatch(actionsOther.setProductsSearchQuery(searchField));

    const abortController = new AbortController();
    const signal = abortController.signal;
    const delayDebounceFn = setTimeout(() => {
      searchProducts(signal, trimmedSearchField);
    }, 300);

    return () => {
      abortController.abort();
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trimmedSearchField]);

  if (hideHeader) {
    return null;
  }

  const onSubmitProduct = (key?: string) => {
    if (key === 'Enter' || !key) {
      dispatch(actionsOther.closeProductSearchPopup(false));
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      if (trimmedSearchField.length < ADVANCED_SEARCH_MIN_LENGTH) {
        return;
      }
      navigate(`${paths.all_products}?search=${trimmedSearchField}`);
    }
  };

  const handleInputFocus = (e?: any) => {
    if (!isProductSearchPopupOpen) {
      dispatch(actionsOther.closeProductSearchPopup(true));
      return;
    }
    if (e) {
      e.currentTarget.select();
    }
  };

  const resetProducts = () => {
    setProducts([]);
    setTags([]);
    setCategories([]);
    setSearchField('');
    setTrimmedSearchField('');
    setShowTags(true);
  };

  const closeNonAuth = () => {
    dispatch(actionsOther.setIsOpenNonAuth(false));
    navigate(paths[isLessThen991 ? 'pay-page' : 'cart-list']);
  };

  const changeZipCode = () => {
    navigate(paths.home);
    dispatch(actionsOther.setIsOpenZipCode(true));
  };

  const onSelectTag = (e: any, value: string) => {
    const searchingValue = value.toLowerCase();
    e.preventDefault();
    setIsLoading(true);
    dispatch(actionsOther.setProductsSearchQuery(searchingValue));
    dispatch(actionsOther.closeProductSearchPopup(false));
    navigate(`${paths.all_products}?search=${searchingValue}`);
    setIsLoading(false);
  };

  if (isLessThen991 && (location.pathname === '/' || location.pathname === '/home') && !isOpenCart) {
    return (
      <div className="mobile-header">
        <span>
          <img className="logo-mobile" src={getShopLogo(true)} alt="" width="82" height="32" />
        </span>
        <div className="mobile-header_right">
          {currentZipCode && (
            <button onClick={changeZipCode} type="button" className="header-zip">
              {currentZipCode}
            </button>
          )}
          <LoginButton user={user} onCloseMenu={() => {}} handleClearCart={handleClearCart} />
        </div>
      </div>
    );
  }

  if (isLessThen991 && (location.pathname !== '/' || isOpenCart)) {
    return null;
  }

  return (
    <>
      <header
        className={`header navbar-expand-lg fixed-top ${isOpenFullSearch && 'searched'} ${
          location && location.pathname === '/lp1' && 'header-landing'
        }`}
        id="header">
        <SystemMessage />
        <HeaderBlockInfo />
        <JsonLd item={schema} />
        <div className="container header-main">
          <div
            className="header-main_left"
            style={{
              justifyContent: isExpressDelivery ? 'flex-start' : undefined,
            }}>
            <HeaderLogo />
            <div className="header-block">
              <div className={`collapse navbar-collapse`} id="menu">
                <nav>
                  <ul className="header-menu header-menu_left">
                    <li>
                      <div
                        className="search-form"
                        onClick={(e) => {
                          // do not close modal if anything inside input is clicked
                          e.stopPropagation();
                        }}>
                        <input
                          placeholder={buildLocale(currentTranslate, 'searchPlaceholder')}
                          className="my-input-search"
                          onChange={(value) => {
                            setSearchField(value.target.value);
                            setTrimmedSearchField(value.target.value.trim());
                          }}
                          value={searchField}
                          onKeyDown={(e) => {
                            if (!trimmedSearchField) {
                              return;
                            }
                            if (e.key === 'Enter') {
                              dispatch(actionsFilters.clearAllSelected());
                            }
                            onSubmitProduct(e.key);
                          }}
                          onFocus={() => handleInputFocus()}
                        />
                        {searchField && (
                          <span className="search-close header-search-close-icon">
                            <img src={searchIcon} alt="" onClick={() => resetProducts()} width="18" height="18" />
                          </span>
                        )}
                        <button
                          type="submit"
                          className="search-form_btn"
                          disabled={!trimmedSearchField}
                          onClick={() => {
                            dispatch(actionsFilters.clearAllSelected());
                            onSubmitProduct();
                          }}
                          aria-label="Suche"
                        />
                        {isProductSearchPopupOpen && (
                          <div className="search-hint main-search-container" id="scrollableDiv">
                            {isLoading && <ProductLoader />}
                            {!isLoading && (
                              <>
                                {!trimmedSearchField && (
                                  <ul className="search-tag_list">
                                    {showTags &&
                                      searchingTags.map((tag) => (
                                        <li key={tag}>
                                          <span
                                            className="search-tag"
                                            onClick={() => {
                                              setSearchField(tag);
                                              setTrimmedSearchField(tag);
                                            }}>
                                            {tag}
                                          </span>
                                        </li>
                                      ))}
                                  </ul>
                                )}
                                {!!tags?.length && (
                                  <>
                                    <div className="search-panel">
                                      {buildLocale(currentTranslate, 'productsSearchProposedResults')}
                                    </div>
                                    <div className="tags-container">
                                      <ul className={`search-tag_list ${isAllTagsOpened ? 'opened' : ''}`}>
                                        {tags.map((tag: ITag) => (
                                          <li key={tag.id}>
                                            <a
                                              onClick={(e) => onSelectTag(e, tag.name)}
                                              className="search-tag"
                                              href={`${paths.all_products}?search=${encodeURIComponent(tag.name)}`}>
                                              {tag.name}
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                      {tags?.length > TAGS_LIMIT_FOR_ROW && (
                                        <div
                                          className={`show-more ${isAllTagsOpened ? 'opened' : ''}`}
                                          onClick={() => setIsAllTagsOpened(true)}>
                                          <span className="btn-more">
                                            {buildLocale(currentTranslate, 'commonBannerLink')}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </>
                                )}
                                {!!products?.length && (
                                  <>
                                    <div className="search-panel">
                                      {buildLocale(currentTranslate, 'productsSearchProductsFound')}
                                    </div>
                                    <ul>
                                      {products.map((productItem: any) => (
                                        <SearchedItem
                                          key={productItem.id}
                                          product={productItem}
                                          setSearchField={setSearchField}
                                          setProducts={setProducts}
                                        />
                                      ))}
                                    </ul>
                                    {isHasMore && (
                                      <div className="search-panel-show-all">
                                        <a
                                          onClick={() => setIsLoading(true)}
                                          className="btn btn-buy show-all"
                                          href={`${paths.all_products}?search=${encodeURIComponent(trimmedSearchField)}`}>
                                          {buildLocale(currentTranslate, 'productsSearchShowAllProducts')} (
                                          {productsCount})
                                        </a>
                                      </div>
                                    )}
                                  </>
                                )}
                                {!!categories?.length && (
                                  <>
                                    <div className="search-panel">
                                      {buildLocale(currentTranslate, 'productsSearchCategoriesFound')}
                                    </div>
                                    <ul>
                                      {categories.map((categoryItem: CustomCategorySearch) => (
                                        <li key={categoryItem.id} className="search-tag_categories">
                                          <a
                                            onClick={() => setIsLoading(true)}
                                            className="search-hint_description product-title-item"
                                            href={`${paths.products(categoryItem.id)}?searchFilter=${encodeURIComponent(trimmedSearchField)}`}>
                                            {!!categoryItem?.parentName?.length && (
                                              <span className="parent-category">
                                                {categoryItem.parentName}
                                                {' > '}
                                              </span>
                                            )}
                                            {categoryItem.name}{' '}
                                            <span className="weight-unit">{categoryItem.count}</span>
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="header-main_right">
            <div className="header-right">
              {currentZipCode && (
                <button onClick={changeZipCode} type="button" className="header-zip">
                  {currentZipCode}
                </button>
              )}
              <nav>
                <ul className="header-menu header-menu_right">
                  <li className="header-right_login">
                    <LoginButton user={user} onCloseMenu={() => {}} handleClearCart={handleClearCart} />
                  </li>
                  <li className="header-right_basket">
                    {isLoadingData && (
                      <div className="pay-loader">
                        <ClipLoader size={30} color={setLoaderColor()} loading={true} />
                      </div>
                    )}
                    <button onClick={toggleCart} className="btn btn-basket">
                      {!count || count === 0 ? null : <span className="btn-basket_items">{count}</span>}
                      <span className="btn-basket_number">{formatPrice(cart.total)}</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
            <AssortmentNoticeModal
              onClose={handleCloseClearCart}
              onConfirm={handleClearCart}
              isOpen={isOpenClearCart}
            />
          </div>
        </div>
        {isOpenFullSearch && isLessThen991 && (
          <div className="search-form_mobile">
            <div
              className="search-form"
              onClick={(e) => {
                // do not close modal if anything inside input is clicked
                e.stopPropagation();
              }}>
              <input
                placeholder="Nach Name, Kategorie oder Rezept suchen"
                className="my-input-search"
                onChange={(value) => {
                  setTrimmedSearchField(value.target.value.trim());
                }}
                value={searchField}
                autoFocus
                onKeyDown={(e) => onSubmitProduct(e.key)}
                onFocus={(e) => handleInputFocus(e)}
              />
              {searchField && (
                <span className="search-close header-search-close-icon">
                  <img src={searchIcon} alt="" onClick={() => resetProducts()} width="18" height="18" />
                </span>
              )}
              <button type="submit" className="search-form_btn" onClick={() => onSubmitProduct()} aria-label="Suche" />
              {isProductSearchPopupOpen && (
                <div className="search-hint main-search-container">
                  <ul>
                    {products.map((item: any) => (
                      <SearchedItem product={item} setSearchField={setSearchField} setProducts={setProducts} />
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <span className="close-search" onClick={() => setIsOpenFullSearch(false)}>
              &#10005;
            </span>
          </div>
        )}
        {!isLessThen991 && (
          <nav>
            <CategoriesList />
            <OwnCategoriesList />
          </nav>
        )}
      </header>
      {isOpenCart && (
        <div onClick={(e) => e.stopPropagation()}>
          <HeaderCart onClose={closeCart} closeNonAuth={closeNonAuth} />
        </div>
      )}
      {isNonAuthOpen && <NonAuthModal isOpen={isNonAuthOpen} onClose={closeNonAuth} />}
    </>
  );
});

export default HeaderComponent;
