import { CreatorReducer } from '../base/base';
import { ICartActions, ICartState } from './cartTypes';
import { RootState } from '../reducer';
import { ICartItem } from '../../typings/ICartItem';
import service from '../../services/service';
import { ID_UNIT_WEIGHT } from '../../constants/constantsId';
import { selectorsOrder } from '../order/orderReducer';
import { ref_cartProducts } from '../../utils/refReact';
import cloneArray from '../../utils/cloneArray';
import { selectorsDelivery } from '../delivery/deliveryReducer';

const init: ICartState = {
  data: [],
  cartData: null,
  isLoading: false,
  error: null,
  isOpenCart: false,
  idSellPoint: null,
  notAvailableProducts: [],
  total: null,
  bottleTotalDeposit: null,
  deliveryCost: null,
  rackTotalDeposit: null,
  addressId: null,
  isLoadingData: false,
  productsPrice: null,
  totalWeightDeposit: null,
};

const creator = new CreatorReducer<ICartActions, ICartState>('cart');
creator.addAction('addProduct', (state, action) => {
  const data = [...state.data, action.payload];
  return { ...state, data };
});
creator.addAction('removeProduct', (state, action) => {
  const data = state.data.filter((item) => +item.product.id !== +action.payload);
  return { ...state, data };
});
creator.addAction('setNotAvailableProducts', (state, action) => {
  return { ...state, notAvailableProducts: action.payload };
});
creator.addAction('setAddressId', (state, action) => {
  return { ...state, addressId: action.payload };
});
creator.addAction('incrProduct', (state, action) => {
  const { id, count } = action.payload;
  // eslint-disable-next-line eqeqeq
  let index = state.data.findIndex((item) => item.product.id == id)!;
  let res = state.data[index].count;
  res = res + count;
  state.data[index] = { ...state.data[index], count: res };

  return { ...state, data: [...state.data] };
});
creator.addAction('decrProduct', (state, action) => {
  const { id, count } = action.payload;
  // eslint-disable-next-line eqeqeq
  let index = state.data.findIndex((item) => item.product.id == id)!;
  let res = state.data[index].count;
  res = res - count;
  if (res < 0.1) {
    return state;
  }
  state.data[index] = { ...state.data[index], count: res };
  return { ...state, data: [...state.data] };
});
creator.addAction('changeCount', (state, action) => {
  const { id, count } = action.payload;
  // eslint-disable-next-line eqeqeq
  let index = state.data.findIndex((item) => item.product.id == id)!;
  if (index === -1) return state;
  state.data[index] = { ...state.data[index], count };
  return { ...state, data: [...state.data] };
});
creator.addAction('changeAlternativeCount', (state, action) => {
  const { id, count } = action.payload;
  // eslint-disable-next-line eqeqeq
  let index = state.data.findIndex((item) => item.product.id == id)!;
  if (index === -1) return state;
  state.data[index] = { ...state.data[index], alternativeCount: count };
  return { ...state, data: [...state.data] };
});
creator.addAction('toggleCart', (state, action) => {
  return { ...state, isOpenCart: action.payload };
});
creator.addAction('setPrices', (state, action) => {
  return {
    ...state,
    total: action.payload.total,
    bottleTotalDeposit: action.payload.bottleTotalDeposit,
    deliveryCost: action.payload.deliveryCost,
    rackTotalDeposit: action.payload.rackTotalDeposit,
    productsPrice: action.payload.productsPrice,
    totalWeightDeposit: action.payload.totalWeightDeposit,
  };
});
creator.addAction('setComment', (state, action) => {
  const { id, comment, services } = action.payload;
  // eslint-disable-next-line eqeqeq
  const index = state.data.findIndex((item) => item.product.id == id)!;
  if (index === -1) return state;
  state.data[index] = { ...state.data[index], comment, services };
  return { ...state, data: [...state.data] };
});
creator.addAction<number>('updateCart', (state, action) => {
  return { ...state, idSellPoint: action.payload };
});
creator.addAction('clear', (state) => ({ ...init, data: [], idSellPoint: state.idSellPoint }));
creator.addAction('updateCartProducts', (state, action) => {
  return { ...state, cartData: action.payload };
});
creator.addAction('setIsLoadingData', (state, action) => {
  return { ...state, isLoadingData: action.payload };
});

const actionsCart = creator.createActions();
const selectorsCart = {
  getCartProducts: (state: RootState) => state.cart.data,
  getCartDataProducts: (state: RootState) => state.cart.cartData,
  getCartProduct: (id: any) => (state: RootState) => {
    const items = selectorsCart.getCartProducts(state) || [];
    return items.find((item) => item.product.id === id);
  },
  getCartDataProduct: (id: any) => (state: RootState) => {
    const items = selectorsCart.getCartDataProducts(state) || [];
    return items.find((item) => item.product.id === id);
  },
  isEmpty: (state: RootState) => state.cart.data.length === 0,
  getGeneralSum: (state: RootState) => {
    const data = selectorsCart.getCartProducts(state);
    return data.reduce((previousValue, current) => {
      const productOption = current.product.productOptions[0];

      const price = productOption.salePrice ? +productOption.salePrice : +productOption.price;
      return previousValue + price * current.count;
    }, 0);
  },
  getAddressId: (state: RootState) => state.cart.addressId,
  getCart: (state: RootState) => state.cart,
  getGeneralCount: (state: RootState) => {
    const data = selectorsCart.getCartDataProducts(state);
    return data?.length || 0;
  },
  getCountProduct: (id: number) => (state: RootState) => {
    const product = selectorsCart.getCartProduct(id)(state);
    if (product) {
      if (product.alternativeCount) return product.alternativeCount;
      return product.count;
    }
    return null;
  },
  getAlternativeCountProduct: (id: number) => (state: RootState) => {
    const product = selectorsCart.getCartProduct(id)(state);
    if (product) {
      return product.alternativeCount ? product.alternativeCount : null;
    }
    return null;
  },
  getIsOpenCart: (state: RootState) => {
    return state.cart.isOpenCart;
  },
  checkProductInCart: (id: any) => (state: RootState) => {
    const data = selectorsCart.getCartProducts(state);
    return data.find((item) => item.product.id + '' === id + '') !== undefined;
  },
  getIdSellPoint: (state: RootState) => state.cart.idSellPoint,
  getIsLoading: (state: RootState) => state.cart.isLoading,
  getIsWeightProducts: (state: RootState) => {
    if (state.cart.data.length === 0) return false;

    return state.cart.data.some((p) => p.product.units === ID_UNIT_WEIGHT);
  },
  getTimeToPrepare: (state: RootState) => {
    const isSelf = selectorsOrder.isDeliverySelf(state);
    if (isSelf) {
      return Math.max(...state.cart.data.map((p) => p.product.timeToPrepare)) / 60 + 10;
    }
    return null;
  },
  getNotAvailableProducts: (state: RootState) => state.cart.notAvailableProducts,
  isLoadingData: (state: RootState) => state.cart.isLoadingData,
  getProductsPrice: (state: RootState) => state.cart.productsPrice,
};

const fetchSaveCart = async (dispatch: any, getStore: () => RootState) => {
  try {
    const state = getStore();
    const products: ICartItem[] = state.cart.data;
    // const idSellPoint = state.cart.idSellPoint;
    const deliveryType = selectorsOrder.getDeliveryType(state);
    const idDelivery = deliveryType ? deliveryType.id : null;
    const zipCode = selectorsDelivery.getZipCode(state);
    //NOTE: moke data for saveCart
    // if (idDelivery === null || idSellPoint === null) {
    //   return;
    // }
    // const idCity = deliveryType!.code === TypeDelivery.courier ? state.city.selectedCity : null;

    dispatch(actionsCart.setLoading(true));
    dispatch(actionsCart.setIsLoadingData(true));
    if (products.length > 0) {
      const res = await service.saveCart(products, idDelivery || 1, 1, zipCode);

      if (res.success) {
        dispatch(actionsCart.updateCartProducts(res.data.cartProducts));
        const { total, bottleTotalDeposit, deliveryCost, rackTotalDeposit, totalWeightDeposit, productsPrice } =
          res.data;
        dispatch(
          actionsCart.setPrices({
            total,
            bottleTotalDeposit,
            deliveryCost,
            rackTotalDeposit,
            totalWeightDeposit,
            productsPrice,
          }),
        );
        ref_cartProducts.current = cloneArray(products);
        if (res.data.notAvailableProducts) {
          dispatch(actionsCart.setNotAvailableProducts(res.data.notAvailableProducts));
        }
        return Promise.resolve();
      } else {
        dispatch(actionsCart.setData(cloneArray(ref_cartProducts.current || [])));
        return Promise.reject();
      }
    } else {
      const res = await service.deleteCart(zipCode);
      if (res) {
        ref_cartProducts.current = [];
        dispatch(
          actionsCart.setPrices({
            total: 0,
            bottleTotalDeposit: 0,
            deliveryCost: 0,
            rackTotalDeposit: 0,
            productsPrice: 0,
            totalWeightDeposit: 0,
          }),
        );
        dispatch(actionsCart.updateCartProducts([]));
        return Promise.resolve();
      } else {
        dispatch(actionsCart.setData(cloneArray(ref_cartProducts.current || [])));
        return Promise.reject();
      }
    }
  } finally {
    dispatch(actionsCart.setLoading(false));
    dispatch(actionsCart.setIsLoadingData(false));
  }
};

export { actionsCart, selectorsCart, fetchSaveCart };
export default creator.createReducerFetch(init);
