import React from 'react';
import FieldAddAddress from './Components/FieldAddAddress';
import FieldAddContact from './Components/FieldAddContact';
import { Link } from 'react-router-dom';
import { usePaths } from '../Routes/RouterList';
import { useDispatch, useSelector } from 'react-redux';
import { actionsUser, selectorsUser } from '../../redux/user/userReducer';
// import FieldAddCard from './Components/FIeldAddCard';
import PaymentCard from '../common/PaymentCard';
import service from '../../services/service';
import { useAxios } from '../../useHooks/useAxios';
import useProfileOptions from '../../useHooks/useProfileOptions';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import { isMetroShop } from '../../constants/shops';
import config from '../../config';

const PersonalDataOption = React.memo(() => {
  const paths = usePaths();
  const ProfileOptions = useProfileOptions();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const dispatch = useDispatch<any>();
  const user = useSelector(selectorsUser.getUser)!;
  const addresses = useSelector(selectorsUser.getAddresses);
  const contacts = useSelector(selectorsUser.getContacts);
  const cards = useSelector(selectorsUser.getCards);
  const { isLoading, request } = useAxios(service.deleteCard);
  const isMetro = isMetroShop(config.shopId);

  const handleDelete = async (id: number) => {
    const res = await request(id);
    if (res.success) {
      dispatch(actionsUser.deleteCard(id));
    }
  };

  return (
    <div className="tab-pane data">
      <Link to={paths.profile()} className="back-link">
        {buildLocale(currentTranslate, 'headerProfile')}
      </Link>
      <p className="profile-block_title">{ProfileOptions['data'].name}</p>
      <div className="profile-block profile-block_info">
        <div className="profile-info_row">
          <p className="profile-info_label">{buildLocale(currentTranslate, 'tINameLabel')}</p>
          <p className="profile-info_name">
            {user.firstName} {user.lastName}
          </p>
        </div>
        {isMetro && (
          <div className="profile-info_row">
            <p className="profile-info_label">{buildLocale(currentTranslate, 'tICompanyLabel')}</p>
            <p className="profile-info_name">{user.company}</p>
          </div>
        )}
        <div className="profile-info_row">
          <p className="profile-info_label">{buildLocale(currentTranslate, 'tIPhoneLabel')}</p>
          <div className="profile-info_txt">
            <p>{user.phone}</p>
          </div>
        </div>
        {user.email && (
          <div className="profile-info_row">
            <p className="profile-info_label">{buildLocale(currentTranslate, 'tIEmailLabel')}</p>
            <div className="profile-info_txt">{user.email}</div>
          </div>
        )}
        <div className="profile-info_row">
          <p className="profile-info_label">{buildLocale(currentTranslate, 'commonAddress')}</p>
          <div className="profile-info_txt">
            <div className="profile-info-addresses">
              {addresses.map((a) => (
                <FieldAddAddress key={a.id} defaultAddress={a} />
              ))}
            </div>
            <FieldAddAddress />
          </div>
        </div>
        <div className="profile-info_row">
          <p className="profile-info_label">{buildLocale(currentTranslate, 'profileSaveContacts')}</p>
          <div className="profile-info_txt">
            <div className="profile-info-addresses">
              {contacts.map((c) => (
                <FieldAddContact key={c.id} defaultContact={c} />
              ))}
            </div>
            <FieldAddContact />
          </div>
        </div>
      </div>
      {/* <h4>{buildLocale(currentTranslate, 'profileSaveCards')}</h4> */}
      <div className="profile-contact_cards scrollbar-outer" style={{ flexWrap: 'wrap' }}>
        {cards.map((c) => (
          <PaymentCard
            key={c.id}
            card={c}
            isActive={false}
            disabled={isLoading}
            onClick={(id) => null}
            onDelete={handleDelete}
          />
        ))}
      </div>
      {/* <FieldAddCard /> */}
    </div>
  );
});

export default PersonalDataOption;
