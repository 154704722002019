import React, { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionsOther, selectorsOther } from '../redux/other/otherReducer';
import { isBrowser } from '../utils/isBrowser';
import { FRACTION_DIGIT, ID_UNIT_WEIGHT } from '../constants/constantsId';
import { useTranslation } from 'react-i18next';

const Cookies = isBrowser && require('cookies-js');

export interface IFormattingContext {
  currentLocale: Locale;
  setLocale: (l: Locale) => any;
  formatPrice: (n: number, c?: boolean) => string;
  formatUnit: (value: number, weight: typeof ID_UNIT_WEIGHT | string) => string;
  format1000Unit: (value: number) => string;
  formatDate: (d: Date) => string;
  longFormatDate: (d: Date) => string;
  defaultPhoneCustom: boolean;
  defaultPhone: string;
}

interface IFormattingContextProps {
  locale?: Locale;
  children: any;
}

export type Locale = 'en' | 'de';

interface IConstant {
  currency: string;
  w1000: string;
  w100: string;
  piece: string;
  shortDate: Intl.DateTimeFormat;
  longDate: Intl.DateTimeFormat;
}

type ILocaleConstant = {
  [name in Locale]: IConstant;
};

const formatTime = new Intl.DateTimeFormat('de', {
  hour: 'numeric',
  minute: 'numeric',
});

const CONSTANTS_UNIT: ILocaleConstant = {
  en: {
    currency: '€',
    piece: '',
    w100: 'gr',
    w1000: 'kg',
    shortDate: new Intl.DateTimeFormat('en', {
      day: 'numeric',
      month: 'numeric',
    }),
    longDate: new Intl.DateTimeFormat('en', {
      day: 'numeric',
      month: '2-digit',
      year: 'numeric',
    }),
  },
  de: {
    currency: '€',
    piece: 'Stck',
    w100: 'G',
    w1000: 'kg',
    shortDate: new Intl.DateTimeFormat('de', {
      day: 'numeric',
      month: 'numeric',
    }),
    longDate: new Intl.DateTimeFormat('de', {
      day: 'numeric',
      month: '2-digit',
      year: 'numeric',
    }),
  },
};
const FormattingContext = React.createContext({} as IFormattingContext);

const useFormattingContext = () => {
  return useContext(FormattingContext);
};
const ProviderFormattingContext: React.FC<IFormattingContextProps> = ({ locale, children }) => {
  const dispatch = useDispatch<any>();
  const { i18n } = useTranslation();
  const currentLocale = useSelector(selectorsOther.getLocale)!;
  const value: IFormattingContext = useMemo(() => {
    return {
      currentLocale,
      setLocale: (locale: Locale) => {
        dispatch(actionsOther.setLocale(locale));
        i18n.changeLanguage(locale);
        isBrowser && Cookies.set('locale', locale);
      },
      formatPrice: (price: number, isShowCurrency: boolean = true) => {
        const formatter = new Intl.NumberFormat(currentLocale, {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        if (isShowCurrency) {
          return `${formatter.format(price)} ${CONSTANTS_UNIT[currentLocale].currency}`;
        } else {
          return `${formatter.format(price)}`;
        }
      },
      formatUnit: (value: number, unit: string) => {
        let strValue = value.toFixed(FRACTION_DIGIT);
        strValue = strValue.replace(/\.?[0]*$/, '');
        if (unit === ID_UNIT_WEIGHT) {
          if (value >= 1) {
            return `${strValue} ${CONSTANTS_UNIT[currentLocale].w1000}`;
          }
          return `${(value * 1000).toFixed(0)} ${CONSTANTS_UNIT[currentLocale].w100}`;
        } else {
          return `${+value.toFixed(FRACTION_DIGIT)}`;
        }
      },
      format1000Unit: (value: number) => {
        return `${(value * 1000).toFixed(0)} ${CONSTANTS_UNIT[currentLocale].w100}`;
      },
      formatDate: (date: Date) => {
        return CONSTANTS_UNIT[currentLocale].shortDate.format(date);
      },
      longFormatDate: (date: Date) => {
        return CONSTANTS_UNIT[currentLocale].longDate.format(date);
      },
      defaultPhoneCustom: currentLocale === 'en',
      defaultPhone: currentLocale === 'en' ? '' : '+380',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocale]);

  return <FormattingContext.Provider value={value}>{children}</FormattingContext.Provider>;
};

export { useFormattingContext, CONSTANTS_UNIT, formatTime };
export default ProviderFormattingContext;
