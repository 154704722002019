import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actionsOther } from '../../redux/other/otherReducer';
import service from '../../services/service';
import HeaderBlockContent from './HeaderBlockContent';
import { getLocaleStorageItem, setLocaleStorageItem } from '../../utils/localStorageHelper';

const HeaderBlockInfo = () => {
  const limit = 2;
  const dispatch = useDispatch<any>();
  const [messages, setMessages] = useState<any>([]);
  const CLOSED_INFO_BLOCK_HEIGHT = 145; // in px

  useEffect(() => {
    clearLocaleMessages();
    getMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const shownMessagesOnly = messages.filter((item: any) => item.isShow);
    const total = shownMessagesOnly.length >= limit ? limit : shownMessagesOnly.length;
    const offsetForMargin = total === 2 ? 1 : total; // height for default block is calculated for max mess count (2) to show breadcrumbs correctly - so do not increase offset for 2 messages
    dispatch(
      actionsOther.publishBlockInfoOptions({
        displayedmMessagesTotal: total,
        headerHightPx: CLOSED_INFO_BLOCK_HEIGHT * offsetForMargin,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const getLocaleMessages = () => {
    const localeMessages = getLocaleStorageItem('localeMessages');
    if (localeMessages) {
      return JSON.parse(localeMessages);
    }
  };

  const setLocaleMessages = (array: Array<any>) => {
    const localeMessages = getLocaleStorageItem('localeMessages');
    if (localeMessages) {
      setLocaleStorageItem('localeMessages', JSON.stringify([...JSON.parse(localeMessages), ...array]));
    } else {
      setLocaleStorageItem('localeMessages', JSON.stringify([...array]));
    }
  };

  const clearLocaleMessages = () => {
    if (getLocaleMessages() && getLocaleMessages().length > 0) {
      const date = new Date();
      const newMessages = getLocaleMessages().filter(
        (item: any) => date >= new Date(item.startDate) && date <= new Date(item.endDate),
      );
      setLocaleStorageItem('localeMessages', JSON.stringify(newMessages));
    }
  };

  const checkForNonActive = (localeArray: Array<any>, array: Array<any>) => {
    return localeArray.filter((item: any) => {
      const currentItem = array.find((i: any) => i.id === item.id);
      return !!currentItem;
    });
  };

  const getMessages = () => {
    service
      .getMessages()
      .then((value: any) => {
        const localeMessagesId =
          getLocaleMessages() && getLocaleMessages().length > 0 && getLocaleMessages().map((item: any) => item.id);
        const updatedMessages = value.data
          .map((item: any) => {
            if (!(localeMessagesId && localeMessagesId.length > 0 && localeMessagesId.includes(item.id))) {
              return { ...item, isShow: true };
            }
            return null;
          })
          .filter((item: any) => item);
        if (getLocaleMessages() && getLocaleMessages().length > 0) {
          const actualMessages = [...checkForNonActive(getLocaleMessages(), value.data), ...updatedMessages];
          setMessages(actualMessages);
        } else {
          setLocaleMessages(updatedMessages);
          setMessages(updatedMessages);
        }
      })
      .catch((e: any) => console.log('ERROR'));
  };

  const changeIsShow = (msg: any) => {
    const currentMessage = messages.find((item: any) => item.id === msg.id);
    const filteredMessages = messages.filter((item: any) => item.id !== msg.id);
    const newMessages = [...filteredMessages, { ...currentMessage, isShow: false }];
    setMessages(newMessages);
    setLocaleStorageItem('localeMessages', JSON.stringify(newMessages));
  };

  if (messages.filter((item: any) => item.isShow).length === 0) {
    return null;
  }

  return (
    <div className="header-block_info">
      {messages.length > 0 &&
        messages
          .filter((item: any) => item.isShow)
          .slice(0, limit)
          .map((message: any, index: number) => (
            <HeaderBlockContent message={message} key={index} changeIsShow={changeIsShow} />
          ))}
    </div>
  );
};

export default HeaderBlockInfo;
