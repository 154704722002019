import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormattingContext } from '../../context/FormattingContext';
import { selectorsCart } from '../../redux/cart/cartReducer';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import CartItemComponent from './CartItemComponent';
import ShipmentModal from '../Modals/ShipmentModal';

const CartList = () => {
  const items: any = useSelector(selectorsCart.getCartDataProducts);
  const notAvailableProducts = useSelector(selectorsCart.getNotAvailableProducts);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const cart = useSelector(selectorsCart.getCart);
  const { formatPrice } = useFormattingContext();
  const bottleTotalDeposit = cart?.bottleTotalDeposit ? +cart.bottleTotalDeposit : 0;
  const rackTotalDeposit = cart?.rackTotalDeposit ? +cart.rackTotalDeposit : 0;
  const deliveryCost = cart?.deliveryCost ? +cart.deliveryCost : 0;
  const totalWeightDeposit = cart?.totalWeightDeposit ? +cart.totalWeightDeposit : 0;
  const total = cart?.total ? +cart.total : 0;
  const [isOpenShipmentModal, setIsOpenShipmentModal] = useState(false);

  return (
    <div className="basket-page_content">
      <div className="basket-page_list">
        {items?.length > 0 &&
          items.map((item) => {
            const isNotAvailable =
              notAvailableProducts && notAvailableProducts.find((p) => p.product.id === item.product.id);
            const setAvailableType = () => {
              if (!isNotAvailable) {
                return false;
              } else if (isNotAvailable && isNotAvailable.count === 1) {
                return 'noProduct';
              } else {
                return 'count';
              }
            };
            return <CartItemComponent item={item} notAvailableType={setAvailableType()} key={item.product.id} />;
          })}
      </div>
      <div className="basket-page_total">
        {bottleTotalDeposit > 0 && (
          <p className="basket-block_bottle">
            <span>{buildLocale(currentTranslate, 'cartTotalBottleDeposit')}</span>
            <span>{formatPrice(bottleTotalDeposit)}</span>
          </p>
        )}
        {rackTotalDeposit > 0 && (
          <p className="basket-block_bottle">
            <span>{buildLocale(currentTranslate, 'cartTotalRackDeposit')}</span>
            <span>{formatPrice(rackTotalDeposit)}</span>
          </p>
        )}
        {deliveryCost >= 0 && (
          <p
            className="basket-block_bottle shipment"
            onClick={() => {
              setIsOpenShipmentModal(true);
            }}>
            <span>{buildLocale(currentTranslate, 'btnDelivery')}</span>
            <span>{formatPrice(deliveryCost)}</span>
          </p>
        )}
        {isOpenShipmentModal && (
          <ShipmentModal isOpen={isOpenShipmentModal} closeModal={() => setIsOpenShipmentModal(false)} />
        )}
        {totalWeightDeposit > 0 && (
          <p className="basket-block_bottle">
            <span>{buildLocale(currentTranslate, 'cartTotalWeightDeposit')}</span>
            <span>{formatPrice(totalWeightDeposit)}</span>
          </p>
        )}
        {total && (
          <p className="total">
            <span>{buildLocale(currentTranslate, 'mobileCouponAccepted')}</span>
            <span>{formatPrice(total)}</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default CartList;
