import { CreatorReducer } from '../base/base';
import { IOtherActions, IOtherState, Settings } from './otherTypes';
import { RootState } from '../reducer';
import service from '../../services/service';
import { DEFAULT_NAME_SETTING } from '../../constants/constantsId';
import { actionsOrder } from '../order/orderReducer';
import { SelectorCity } from '../city/cityReducer';

const init: IOtherState = {
  searchQueries: [],
  locale: 'de',
  settings: null,
  isSystemError: false,
  isPaymentError: false,
  maskCard: '',
  content: [],
  connectKey: '',
  tempData: null,
  tempData2: null,
  token: '',
  description: '',
  preAuth: undefined,
  isOpenDeliveryType: false,
  menu: [],
  hideHeader: false,
  is404Error: false,
  isBot: false,
  productsSearchingTotal: 0,
  isProductSearchPopupOpen: false,
  isOpenLogin: false,
  openLoginData: { isOpenLogin: false, redirectLink: '' },
  isOpenNonAuth: false,
  toggleSystemMessage: { type: 'none', isOpen: false, message: '' },
  blockInfoOptions: { displayedmMessagesTotal: 0, headerHightPx: 0 },
  minOrderPrice: 0,
  productsSearchQuery: '',
  freeDeliveryOrderAmount: null,
  isOpenZipCode: false,
  isDesktop: true,
};
const MAX = 5;

const creator = new CreatorReducer<IOtherActions, IOtherState>('other');
creator.addAction('addSearchQueries', (state, action) => {
  const searchQueries = state.searchQueries.filter((s) => s !== action.payload);
  if (searchQueries.length >= MAX) {
    //searchQueries.pop();
  }
  searchQueries.unshift(action.payload);
  state.searchQueries = [...searchQueries];
  return { ...state };
});
creator.addAction('removeSearchQueries', (state, action) => {
  const searchQueries = state.searchQueries.filter((s) => s !== action.payload);
  state.searchQueries = [...searchQueries];
  return { ...state };
});
creator.addAction('setLocale', (state, action) => {
  return { ...state, locale: action.payload };
});
creator.addAction('setMinOrderPrice', (state, action) => {
  return { ...state, minOrderPrice: action.payload };
});
creator.addAction('setSettings', (state, action) => {
  return { ...state, settings: action.payload };
});
creator.addAction('setSystemError', (state, action) => {
  return { ...state, isSystemError: action.payload };
});
creator.addAction('setDeliveryPrices', (state, action) => {
  return { ...state, deliveryPrices: action.payload };
});
creator.addAction('setData', (state, action) => {
  return { ...state, ...action.payload };
});
creator.addAction(actionsOrder.clear, (state, action) => {
  return { ...state, maskCard: '', preAuth: undefined };
});
creator.addAction('productsSearchingTotal', (state, action) => {
  return { ...state, productsSearchingTotal: action.payload };
});
creator.addAction('closeProductSearchPopup', (state, action) => {
  return { ...state, isProductSearchPopupOpen: action.payload };
});
creator.addAction('setIsOpenLogin', (state, action) => {
  return { ...state, isOpenLogin: action.payload };
});
creator.addAction('setOpenLoginData', (state, action) => {
  return { ...state, openLoginData: action.payload };
});
creator.addAction('toggleSystemMessage', (state, action) => {
  return { ...state, toggleSystemMessage: action.payload };
});
creator.addAction('publishBlockInfoOptions', (state, action) => {
  return { ...state, blockInfoOptions: action.payload };
});
creator.addAction('setProductsSearchQuery', (state, action) => {
  return { ...state, productsSearchQuery: action.payload };
});
creator.addAction('setIsOpenNonAuth', (state, action) => {
  return { ...state, isOpenNonAuth: action.payload };
});
creator.addAction('setFreeDeliveryOrderAmount', (state, action) => {
  return { ...state, freeDeliveryOrderAmount: action.payload };
});
creator.addAction('setIsOpenZipCode', (state, action) => {
  return { ...state, isOpenZipCode: action.payload };
});
creator.addAction('setIsDesktop', (state, action) => {
  return { ...state, isDesktop: action.payload };
});

const actionsOther = creator.createActions();

const selectorsOther = {
  getSearchQueries: (state: RootState) => state.other.searchQueries,
  getLocale: (state: RootState) => state.other.locale,
  isSettings: (state: RootState) => state.other.settings === null,
  getSetting: (id: string | number) => (state: RootState) => {
    if (!state.other.settings) {
      return {
        step: 30,
        offset: 60,
        from: '12:00',
        to: '21:00',
        range: 60,
      };
    }
    const setting = state.other.settings![id] || {};
    if (id === DEFAULT_NAME_SETTING) {
      return SelectorCity.getSettingForDelivery(state);
    }
    const sellPoint: any = state.sellPoints.data.find((s) => s.id === +id) || {};
    const [from, to] = sellPoint.workTime ? sellPoint.workTime.split('-') : ['10:00', '23:00'];

    return {
      step: setting.order_time_step ? +setting.order_time_step : 30,
      offset: setting.order_offset_time ? +setting.order_offset_time : 60,
      from,
      to,
      range: 60,
    };
  },
  getSettingPaymentType: (id: any) => (state: RootState) => {
    if (!id) {
      return null;
    }

    const payment_type = state.other.settings![id].payment_type;
    return payment_type ? +payment_type : null;
  },
  getIdSellPoint: (state: RootState) => {
    if (!state.other.settings) return -1;
    return +state.other.settings![DEFAULT_NAME_SETTING].default_price_sell_point!;
  },
  getPhone: (state: RootState) => {
    if (!state.other.settings) return '';
    return state.other.settings![DEFAULT_NAME_SETTING].delivery_phone!;
  },
  getMaxProductsInSlider: (state: RootState) => {
    if (!state.other.settings) return 20;
    return state.other.settings![DEFAULT_NAME_SETTING].max_products_numbers_in_sliders;
  },
  getPublicMessage: (state: RootState) => {
    if (!state.other.settings) return undefined;
    return state.other.settings![DEFAULT_NAME_SETTING].public_message;
  },
  getIncreasePercentage: (state: RootState) => {
    if (!state.other.settings) return 15;
    return +state.other.settings![DEFAULT_NAME_SETTING].increase_percentage!;
  },
  getPreauthPayment: (state: RootState) => {
    if (!state.other.settings) return true;
    return state.other.settings![DEFAULT_NAME_SETTING].preauth_payment! === 'true';
  },
  getIsSystemError: (state: RootState) => {
    return state.other.isSystemError;
  },
  getIsPaymentError: (state: RootState) => {
    return state.other.isPaymentError;
  },
  getMinDeliveryPrice: (state: RootState) => {
    return state.other.minOrderPrice;
  },
  getMaskCard: (state: RootState) => {
    return state.other.maskCard;
  },
  getContent: (state: RootState) => {
    return state.other.content;
  },
  getConnectKey: (state: RootState) => {
    return state.other.connectKey;
  },
  getTemp: (state: RootState) => {
    return [state.other.tempData, state.other.tempData2];
  },
  getDataCard: (state: RootState) => {
    return {
      ERRORIPSCODE: 'null',
      TOKEN: state.other.token,
      CARD_MASK: state.other.maskCard,
      DESCRIPTION: state.other.description,
    };
  },
  getPreAuthData: (state: RootState) => state.other.preAuth,
  getIsOpenDeliveryType: (state: RootState) => state.other.isOpenDeliveryType,
  getMenu: (state: RootState) => state.other.menu,
  getHideHeader: (state: RootState) => state.other.hideHeader,
  getIs404Error: (state: RootState) => state.other.is404Error,
  getIsBot: (state: RootState) => state.other.isBot,
  getProductSearchPopupOpenState: (state: RootState) => state.other.isProductSearchPopupOpen,
  getProductsSearchingTotal: (state: RootState) => state.other.productsSearchingTotal,
  isOpenLogin: (state: RootState) => state.other.isOpenLogin,
  getOpenLoginData: (state: RootState) => state.other.openLoginData,
  toggleSystemMessage: (state: RootState) => state.other.toggleSystemMessage,
  getBlockInfoOptions: (state: RootState) => state.other.blockInfoOptions,
  getProductsSearchQuery: (state: RootState) => state.other.productsSearchQuery,
  getNonAuth: (state: RootState) => state.other.isOpenNonAuth,
  getFreeDeliveryOrderAmount: (state: RootState) => state.other.freeDeliveryOrderAmount,
  getOpenZipCode: (state: RootState) => state.other.isOpenZipCode,
  isDesktop: (state: RootState) => state.other.isDesktop,
};

const fetchGetAllSettings = (locale?: any) => async (dispatch: any) => {
  try {
    const res = (await service.getAllSetups(locale)) || [];
    const setting = (await service.getSettings(locale)) || [];

    const minPrice = res.find((item) => item.setting.code === 'order_min_price');

    if (minPrice && minPrice.value && !isNaN(+minPrice.value)) {
      dispatch(actionsOther.setMinOrderPrice(+minPrice.value));
    }

    let data: Settings = {
      [DEFAULT_NAME_SETTING]: {},
    };
    setting.forEach((s) => {
      data[DEFAULT_NAME_SETTING][s.code] = s.isActive ? s.defaultValue : undefined;
    });
    res
      .filter((s) => s.sellPoint)
      .forEach((setting) => {
        const value = setting.value || setting.setting.defaultValue;
        const code = setting.setting.code;
        const key = setting.sellPoint!.id;

        if (!data[key]) {
          data[key] = {};
        }
        data[key][code] = value;
      });

    dispatch(
      actionsOrder.setData({
        idDeliveryPrice: +data[DEFAULT_NAME_SETTING].default_delivery_price!,
      }),
    );
    dispatch(actionsOther.setSettings(data));
  } catch (e) {}
};

const thunkGetAllSettings = ({ store, locale }: any) => {
  return store.dispatch(fetchGetAllSettings(locale));
};

export { actionsOther, selectorsOther, fetchGetAllSettings, thunkGetAllSettings };
export default creator.createReducer(init);
