import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { ICategoryState } from './category/categoryTypes';
import categoryReducer from './category/categoryReducer';
import cartReducer from './cart/cartReducer';
import { ICartState } from './cart/cartTypes';
import { IOtherState } from './other/otherTypes';
import otherReducer from './other/otherReducer';
import { IUserState } from './user/userTypes';
import userReducer from './user/userReducer';
import { IOrderState } from './order/orderTypes';
import orderReducer from './order/orderReducer';
import { ISellPointsState } from './sellPoints/sellPointsTypes';
import sellPointsReducer from './sellPoints/sellPointsReducer';
import { ICityState } from './city/cityTypes';
import cityReducer from './city/cityReducer';
import { ILocaleState } from './locale/localeTypes';
import localeReducer from './locale/localeReducer';
import { IOwnCategoriesState } from './ownCategories/ownCategoriesTypes';
import ownCategoriesReducer from './ownCategories/ownCategoriesReducer';
import { IFiltersState } from './filters/filtersTypes';
import filtersReducer from './filters/filtersReducer';
import { IHeaderState } from './header/headerTypes';
import headerReducer from './header/headerReducer';
import { IDeliveryState } from './delivery/deliveryTypes';
import deliveryReducer from './delivery/deliveryReducer';

const otherPersistConfig = (customStorage: any) => ({
  key: 'other',
  storage: customStorage,
  whitelist: ['searchQueries', 'productsSearchQuery'],
});

const filtersPersistConfig = (customStorage: any) => ({
  key: 'filter',
  storage: customStorage,
});

const orderPersistConfig = (customStorage: any) => ({
  key: 'order',
  storage: customStorage,
  whitelist: ['draftId', 'expiredTime'],
});

const cityPersistConfig = (customStorage: any) => ({
  key: 'city',
  storage: customStorage,
  whitelist: ['data'],
});

const deliveryPersistConfig = (customStorage: any) => ({
  key: 'delivery',
  storage: customStorage,
});

export interface RootState {
  category: ICategoryState;
  cart: ICartState;
  other: IOtherState;
  user: IUserState;
  order: IOrderState;
  sellPoints: ISellPointsState;
  city: ICityState;
  locale: ILocaleState;
  ownCategories: IOwnCategoriesState;
  filters: IFiltersState;
  header: IHeaderState;
  delivery: IDeliveryState;
}

const reducers = (customStorage: any) =>
  combineReducers({
    category: categoryReducer,
    cart: cartReducer,
    other: persistReducer(otherPersistConfig(customStorage), otherReducer),
    user: userReducer,
    order: persistReducer(orderPersistConfig(customStorage), orderReducer),
    sellPoints: sellPointsReducer,
    city: persistReducer(cityPersistConfig(customStorage), cityReducer),
    locale: localeReducer,
    ownCategories: ownCategoriesReducer,
    filters: persistReducer(filtersPersistConfig(customStorage), filtersReducer),
    header: headerReducer,
    delivery: persistReducer(deliveryPersistConfig(customStorage), deliveryReducer),
  });

export default reducers;
