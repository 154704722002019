import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { buildLocale } from '../../../utils/buildLocale';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { IReview } from '../../../typings/IReview';
import service from '../../../services/service';
import ProductLoader from '../../Loaders/ProductLoader';
import ReviewStars from './ReviewStars';
import { useFormattingContext } from '../../../context/FormattingContext';
import ManageProductReviewWidget from './ManageProductReviewWidget';

interface ProductReviewWidgetProps {
  productId: number;
  productTitle: string;
  ratingValue: number;
  itemsCount: number;
}

const ViewProductReviewWidget = (props: ProductReviewWidgetProps) => {
  const { productId, productTitle, ratingValue, itemsCount } = props;
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [reviews, setReviews] = useState<IReview[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isManagingAvailable, setIsManagingAvailable] = useState(false);
  const [isShowSuccessMessage, setIsShowSuccessMessage] = useState(false);
  const { longFormatDate } = useFormattingContext();

  const getReviews = () => {
    setIsLoading(true);

    service
      .getReviews(productId)
      .then((res: any) => {
        const reviewsData = res?.data || [];
        setReviews((prevReviews) => [...prevReviews, ...reviewsData]);
        setIsLoading(false);
      })
      .catch((e) => {
        setReviews([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (productId && itemsCount) {
      getReviews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const toggleManagingBlock = (e) => {
    e.preventDefault();
    setIsShowSuccessMessage(false);
    setIsManagingAvailable(!isManagingAvailable);
  };

  const onAfterSubmit = () => {
    setIsShowSuccessMessage(true);
    setIsManagingAvailable(false);
  };

  return (
    <>
      {isLoading && <ProductLoader />}
      {!isLoading && (
        <>
          <hr />
          <h3>
            {buildLocale(currentTranslate, 'commonReviews')}
            <span className="product-reviews_header_product_title">{productTitle}</span>
            <span>({itemsCount})</span>
          </h3>
          <div className="product-reviews_bottom_head">
            <div>
              <ReviewStars
                isEditable={false}
                ratingValue={ratingValue}
                additionalData={
                  <>
                    <span className="product-reviews_bottom_head_rate">
                      {Number.parseFloat(ratingValue?.toString() || '0')}/5
                    </span>
                    <span className="product-reviews_bottom_head_rate_title">
                      {buildLocale(currentTranslate, 'averageRating')}
                    </span>
                  </>
                }
              />
            </div>
            <div className="product-reviews_bottom_head_button">
              <span className="btn-more" onClick={toggleManagingBlock}>
                {buildLocale(currentTranslate, 'writeReview')}
              </span>
            </div>
          </div>
          {isShowSuccessMessage && <p className="reviewMessage">{buildLocale(currentTranslate, 'reviewMessage')}</p>}
          <hr />
          {isManagingAvailable && (
            <>
              <ManageProductReviewWidget productId={productId} onAfterSubmit={onAfterSubmit} />
              <hr />
            </>
          )}

          {reviews.map((review: IReview) => (
            <div className="product-reviews_bottom_body" key={review.id}>
              <div className="product-reviews_bottom_body_stars">
                <ReviewStars isEditable={false} ratingValue={review.rating} />
              </div>
              <div className="product-reviews_bottom_body_text">
                {!!review.text?.length && <p>{review.text}</p>}
                <p>
                  <span className="description">
                    {!!review.client?.firstName && (
                      <span>
                        <b>{buildLocale(currentTranslate, 'ratingVon')}</b>: {review.client.firstName.split(' ').at(0)}
                      </span>
                    )}
                    {!!review.client?.lastName?.length && (
                      <span className="last-name-title">{review.client.lastName}.</span>
                    )}
                  </span>

                  <span className="description">
                    <b>{buildLocale(currentTranslate, 'ratingAm')}:</b> {longFormatDate(new Date(review.date))}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default ViewProductReviewWidget;
