import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useFormattingContext } from '../../context/FormattingContext';
import { actionsCart, selectorsCart } from '../../redux/cart/cartReducer';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { actionsOther, selectorsOther } from '../../redux/other/otherReducer';
import { usePaths } from '../Routes/RouterList';
import { blockBodyScroll } from '../../utils/blockBodyScroll';
import { buildLocale } from '../../utils/buildLocale';
import { useLessThen991 } from '../../utils/mediaQuery';
import HeaderCart from '../Cart/HeaderCart';
import NonAuthModal from '../Modals/NonAuthModal';
import { actionsFilters } from '../../redux/filters/filtersReducer';
import { useNavigate } from 'react-router';

const FooterMobile = () => {
  const paths = usePaths();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const sum = useSelector(selectorsCart.getGeneralSum);
  const count = useSelector(selectorsCart.getGeneralCount);
  const isOpenCart = useSelector(selectorsCart.getIsOpenCart);
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLessThen991 = useLessThen991(isDesktop);
  const isNonAuthOpen = useSelector(selectorsOther.getNonAuth);
  const { formatPrice } = useFormattingContext();
  const cart = useSelector(selectorsCart.getCart);

  const closeCart = () => {
    dispatch(actionsCart.toggleCart(false));
    blockBodyScroll(false);
  };

  const toggleCart = () => {
    if (sum > 0 && !isBasketPage) dispatch(actionsCart.toggleCart(!isOpenCart));
  };

  const isBasketPage = location && location.pathname.includes('basket');
  const isFavoritesPage = location && location.pathname.includes('wishlist');

  const closeNonAuth = () => {
    dispatch(actionsOther.setIsOpenNonAuth(false));
    navigate(paths[isLessThen991 ? 'pay-page' : 'cart-list']);
    closeCart();
  };

  const clearFilters = () => {
    dispatch(actionsFilters.clearAllSelected());
  };

  return (
    <div className="mobile-menu">
      <NavLink to={paths.main} className="mobile-button" onClick={() => clearFilters()}>
        <span className="icon icon-home"></span>
        <span className="text">{buildLocale(currentTranslate, 'footerMobileHome')}</span>
      </NavLink>
      <NavLink to={paths.categories_page()} className="mobile-button" onClick={() => clearFilters()}>
        <span className="icon icon-categories"></span>
        <span className="text">{buildLocale(currentTranslate, 'footerMobileCategories')}</span>
      </NavLink>
      <NavLink to={paths.searching} className="mobile-button" onClick={() => clearFilters()}>
        <span className="icon icon-search"></span>
        <span className="text">{buildLocale(currentTranslate, 'footerMobileSearch')}</span>
      </NavLink>
      <NavLink
        to={paths.wishlist}
        className="mobile-button"
        onClick={() => {
          if (!isFavoritesPage) {
            clearFilters();
          }
        }}>
        <span className="icon icon-fav"></span>
        <span className="text">{buildLocale(currentTranslate, 'footerMobileWishlist')}</span>
      </NavLink>
      <button className={`mobile-button ${isBasketPage ? 'active' : ''}`} onClick={() => toggleCart()}>
        <span className="icon icon-cart">{count ? <span className="number">{count || ''}</span> : ''}</span>
        <span className="text">{cart ? formatPrice(+cart.total) : formatPrice(0)}</span>
      </button>
      {isOpenCart && (
        <div>
          <HeaderCart onClose={closeCart} closeNonAuth={closeNonAuth} />
          <div
            onClick={closeCart}
            style={{ position: 'fixed', width: '100%', height: '100%', zIndex: 1000, top: 0, left: 0 }}
          />
        </div>
      )}
      {isNonAuthOpen && <NonAuthModal isOpen={isNonAuthOpen} onClose={closeNonAuth} />}
    </div>
  );
};

export default FooterMobile;
