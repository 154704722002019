import React, { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useLessThen991 } from '../utils/mediaQuery';
import { usePaths } from '../components/Routes/RouterList';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogout, refreshUser, selectorsUser } from '../redux/user/userReducer';
import { isBrowser } from '../utils/isBrowser';
import withProtected from '../useHooks/withProteced';
import useProfileOptions from '../useHooks/useProfileOptions';
import { actionsCart } from '../redux/cart/cartReducer';
import { actionsOther, selectorsOther } from '../redux/other/otherReducer';
import { buildLocale } from '../utils/buildLocale';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { useFormattingContext } from '../context/FormattingContext';
import { useNavigate } from 'react-router';

const broadcast = isBrowser && require('../context/BroadcastContext').default;

const ProfilePage = React.memo((props: any) => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const dispatch = useDispatch<any>();
  const paths = usePaths();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const ProfileOptions = useProfileOptions();
  let option = params['option'];
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLessThen991 = useLessThen991(isDesktop);
  option = isLessThen991 ? option : !option ? 'data' : option;
  const { formatPrice } = useFormattingContext();
  const user = useSelector(selectorsUser.getDataUser);
  const creditsBalance = user?.credits?.balance ? +user?.credits?.balance : 0;

  const Component = option ? ProfileOptions[option].component : () => <div />;

  const handleLogout = () => {
    broadcast.broadcastLogout();
    dispatch(fetchLogout);
    handleClearCart();
    navigate(paths.main);
  };

  useEffect(() => {
    dispatch(refreshUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearCart = () => {
    dispatch(actionsCart.clear());

    dispatch(
      actionsOther.setData({
        isOpenDeliveryType: true,
      }),
    );
  };
  //TODO
  return (
    <section className="profile-content">
      {isLessThen991 && location.pathname === '/profile' && (
        <Link to={paths.home} className="back-link" style={{ margin: '1rem' }}>
          {buildLocale(currentTranslate, 'loginGoBack')}
        </Link>
      )}
      {!(isLessThen991 && option) && (
        <aside className="profile-aside">
          <div className="profile-aside_top">
            <h1>{buildLocale(currentTranslate, 'headerProfile')}</h1>
            <div className="profile-aside_credits">
              {buildLocale(currentTranslate, 'creditsProfile')} :
              <span className="number">{formatPrice(creditsBalance)}</span>
              <div className="info-block">
                <span className="info">i</span>
                <div className="info-hover">{buildLocale(currentTranslate, 'creditsProfileInfo')}</div>
              </div>
            </div>
            <ul className="nav profile-menu">
              {Object.keys(ProfileOptions).map((key, index) => {
                if (ProfileOptions[key].isDisabled) {
                  return (
                    <li key={key} className={`nav-${key}`}>
                      <span className="nav-link todo-disabled">{`${index + 1}. ${ProfileOptions[key].name}`}</span>
                    </li>
                  );
                }
                return (
                  <li key={key} className={`nav-${key}`}>
                    <Link
                      to={paths.profile(key)}
                      className={`nav-link ${key === option ? 'active' : ''}`}>{`${ProfileOptions[key].name}`}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <ul className="mobile-user_static">
            <li>
              <Link to={paths.about_us}>{buildLocale(currentTranslate, 'footerAboutUs')}</Link>
            </li>
            <li>
              <Link to={paths.regional_products}>{buildLocale(currentTranslate, 'footerRegionalProducts')}</Link>
            </li>
            <li>
              <Link to={paths.regional_partners}>{buildLocale(currentTranslate, 'footerRegionalPartners')}</Link>
            </li>
            <li>
              <Link to={paths.brands}>{buildLocale(currentTranslate, 'brandsFooter')}</Link>
            </li>
            <li>
              <Link to={paths.bio_badges}>{buildLocale(currentTranslate, 'footerBioBadges')}</Link>
            </li>
            <li>
              <Link to={paths.delivery}>{buildLocale(currentTranslate, 'footerDelivery')}</Link>
            </li>
            <li>
              <Link to={paths.produktrueckrufe}>{buildLocale(currentTranslate, 'footerProduktrueckrufe')}</Link>
            </li>
            <li>
              <Link to={paths.presse}>{buildLocale(currentTranslate, 'footerPresse')}</Link>
            </li>
            <li>
              <Link to={paths.faq}>{buildLocale(currentTranslate, 'footerFaq')}</Link>
            </li>
            <li>
              <Link to={paths.agb}>{buildLocale(currentTranslate, 'footerAgb')}</Link>
            </li>
            <li>
              <Link to={paths.widerrufsrecht}>{buildLocale(currentTranslate, 'footerWiderrufsrecht')}</Link>
            </li>
            <li>
              <Link to={paths.confidentiality}>{buildLocale(currentTranslate, 'footerConfid')}</Link>
            </li>
            <li>
              <Link to={paths.impressum}>{buildLocale(currentTranslate, 'footerImpressum')}</Link>
            </li>
          </ul>
          <button onClick={handleLogout} className="btn-logout">
            <span>{'commonExit'}</span>
          </button>
        </aside>
      )}
      <div className="profile-right">
        <div className="tab-content">
          <Component />
        </div>
      </div>
    </section>
  );
});

export default withProtected(ProfilePage);
