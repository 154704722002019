import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';

interface IProps {
  message: any;
  changeIsShow: any;
}

const HeaderBlockContent = (props: IProps) => {
  const { message, changeIsShow } = props;
  const [isOpen, setIsOpen] = useState(true);
  const [isShowBtn, setIsShowBtn] = useState(false);
  const blockRef = useRef<any>({});
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  const updateMessage = () => {
    changeIsShow(message);
    setIsOpen(true);
  };

  useEffect(() => {
    if (blockRef.current && blockRef.current.offsetHeight > 75) {
      setIsOpen(false);
      setIsShowBtn(true);
    } else {
      setIsOpen(true);
      setIsShowBtn(false);
    }
  }, [message]);

  if (!message) {
    return null;
  }

  return (
    <div className="block-one_out">
      <div
        ref={blockRef}
        className={`block-one ${message.type.code} ${isOpen ? 'notification-open' : 'notification-close'}`}
        dangerouslySetInnerHTML={{
          __html: message.text,
        }}></div>
      <button className="block-one_close" onClick={() => updateMessage()}>
        &#10005;
      </button>
      {isShowBtn && (
        <div className="block-one_more--container">
          <button className="btn-more" onClick={() => setIsOpen(!isOpen)}>
            {buildLocale(currentTranslate, isOpen ? 'childrenLess' : 'childrenMore')}
          </button>
        </div>
      )}
    </div>
  );
};

export default HeaderBlockContent;
