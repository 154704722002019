import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { actionsCart, selectorsCart } from '../../redux/cart/cartReducer';
import config from '../../config';
import { useFormattingContext } from '../../context/FormattingContext';
import { isMetroShop } from '../../constants/shops';
import { buildLocale } from '../../utils/buildLocale';
import { calculateFullPrice, getUnitType, weightableUnit } from '../../utils/calculateUnits';
import getPriceWithoutVAT from '../../utils/getPriceWithoutVAT';
import UnitPrice from '../Products/UnitPrice';
import CartCountItem from '../Cart/CartCountItem';
import PriceRangeTable from '../Products/PriceRangeTable';
import { IProduct, IProductPriceRange } from '../../typings/IProduct';
import { useLessThen991 } from '../../utils/mediaQuery';
import { Link, useLocation } from 'react-router-dom';
import ShipmentModal from '../Modals/ShipmentModal';
import { selectorsDelivery } from '../../redux/delivery/deliveryReducer';
import { actionsOther, selectorsOther } from '../../redux/other/otherReducer';
import service from '../../services/service';
import { refreshUser, selectorsUser } from '../../redux/user/userReducer';
import { useNavigate } from 'react-router';
import { usePaths } from '../Routes/RouterList';
import bellIcon from '../../assets/images/bell.svg';
import bellFillIcon from '../../assets/images/bell-fill.svg';

interface IProps {
  product: IProduct;
  closeModal?: () => void;
  addProductToCart: (count: number) => void;
  orderBtnTitle: string;
}

const PriceBlock = (props: IProps) => {
  const { product, addProductToCart, orderBtnTitle, closeModal } = props;
  const formattedUnitType = getUnitType(product.unit?.type);
  const { formatPrice } = useFormattingContext();
  const productCart = useSelector(selectorsCart.getCartProduct(product?.id));
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isAuth = useSelector(selectorsUser.isAuth);
  const paths = usePaths();
  const navigate = useNavigate();
  const isLess991 = useLessThen991(isDesktop);
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const isMyBioShop = !isMetroShop(config.shopId);
  const isWeightable = product.isWeightable;
  const unit = product.unit;
  const quantityPerPack = product.quantityPerPack;
  const formattedWightableUnit = weightableUnit(isWeightable, product?.unit.type);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const currentProductOption = product?.productOptions.at(0);
  const price = product && currentProductOption ? +currentProductOption.price : 0;
  const isAvailable = currentProductOption?.availableAmount !== 0;
  const isActive = product?.isActive;
  const taxPercent = product?.taxCategory?.percent || 0;
  const [isOpenShipmentModal, setIsOpenShipmentModal] = useState(false);
  const salePrice = product && currentProductOption ? currentProductOption.salePrice : null;
  const productPriceRanges = product && currentProductOption ? currentProductOption.productPriceRanges : [];
  const priceWithoutVAT = getPriceWithoutVAT(price, taxPercent);
  const salePriceWithoutVAT = getPriceWithoutVAT(+salePrice!, taxPercent);
  const productPriceRangesWithoutVAT = productPriceRanges.map(({ price, quantity }) => {
    return {
      price: getPriceWithoutVAT(+price, taxPercent).toString(),
      quantity,
    } as IProductPriceRange;
  });
  const isBasketPage = location && location.pathname.includes('basket');
  const count = useSelector(selectorsCart.getGeneralCount);
  const sum = useSelector(selectorsCart.getGeneralSum);
  const isOpenCart = useSelector(selectorsCart.getIsOpenCart);
  const isLoadingCartRequest = useSelector(selectorsCart.getIsLoading);
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const isBoxDeliveryAllowed = !product?.isBoxDeliveryAllowed && isInCourierArea === false;
  const [isSubscriptionMessageLoaded, setIsSubscriptionMessageLoaded] = useState(false);
  const productSubscriptionForProduct =
    useSelector(selectorsUser.getProductSubscriptionByProductOptionId(currentProductOption?.id || null)) || null;
  const categoryIdForProduct = product.customCategories.at(0)?.id;

  const toggleCart = () => {
    if (sum > 0 && !isBasketPage) dispatch(actionsCart.toggleCart(!isOpenCart));
  };

  const tryToSubscribe = () => {
    if (!currentProductOption) {
      return;
    }
    if (!isAuth) {
      if (isLess991) {
        navigate(paths.login, {
          state: {
            from: location.pathname,
          },
        });
        return;
      }
      dispatch(
        actionsOther.setOpenLoginData({
          isOpenLogin: true,
          redirectLink: location.pathname,
        }),
      );
      return;
    }
    service.subscribeClientToProduct(currentProductOption.id).then(() => dispatch(refreshUser));
  };

  const tryToUnsubscribe = () => {
    if (!productSubscriptionForProduct) {
      return;
    }
    service.unsubscribeClientFromProduct(productSubscriptionForProduct.id).then(() => dispatch(refreshUser));
  };

  useEffect(() => {
    setIsSubscriptionMessageLoaded(true);
  }, [productSubscriptionForProduct]);

  useEffect(() => {
    if (isAuth && !productSubscriptionForProduct) {
      dispatch(refreshUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  const getCategoryLink = () => {
    if (product.customCategories.at(0)?.parent === null) {
      return `${paths.own_categories(product.customCategories.at(0)?.id || null)}`;
    }
    return `${paths.products(product.customCategories.at(0)?.id || null)}`;
  };

  return (
    <>
      {isAvailable && (
        <>
          {isLess991 && (
            <>
              {isMyBioShop && (
                <>
                  <ul className="product-price">
                    {salePrice !== null ? (
                      <li className="price">
                        <div className="product-price_sale">
                          <span className="number">
                            {formatPrice(price * 1)}
                            {formattedWightableUnit}
                          </span>
                          <div className="number-sale">
                            {formatPrice(+salePrice * 1)}
                            {formattedWightableUnit}
                          </div>
                        </div>
                        {product.unit && (
                          <UnitPrice
                            price={price}
                            salePrice={salePrice}
                            unit={product.unit}
                            quantityPerPack={product.quantityPerPack}
                            isWeightable={product.isWeightable}
                          />
                        )}
                      </li>
                    ) : (
                      <li className="price">
                        <span>
                          {formatPrice(price * 1)}
                          {formattedWightableUnit}
                        </span>
                        {product.unit && (
                          <UnitPrice
                            price={price}
                            salePrice={salePrice}
                            unit={product.unit}
                            quantityPerPack={product.quantityPerPack}
                            isWeightable={product.isWeightable}
                          />
                        )}
                      </li>
                    )}
                  </ul>
                  {!taxPercent ? null : (
                    <>
                      <p className="shipment-tax">
                        {buildLocale(currentTranslate, 'shipmentTitle1')} {taxPercent}%{' '}
                        {buildLocale(currentTranslate, 'shipmentTitle2')}{' '}
                        <span
                          onClick={() => {
                            setIsOpenShipmentModal(true);
                          }}>
                          {buildLocale(currentTranslate, 'shipmentLink')}
                        </span>
                      </p>
                    </>
                  )}
                  {isOpenShipmentModal && (
                    <ShipmentModal isOpen={isOpenShipmentModal} closeModal={() => setIsOpenShipmentModal(false)} />
                  )}
                  <PriceRangeTable price={salePrice || price} productPriceRanges={productPriceRanges} />
                  <div className="product-button">
                    <div className="buttons">
                      <div className="buttons-div">
                        {closeModal && (
                          <button className="btn btn-close_mobile" type="button" onClick={() => closeModal()}></button>
                        )}
                        {productCart && isActive ? (
                          <CartCountItem item={productCart} />
                        ) : (
                          <button
                            className="btn btn-blue"
                            type="submit"
                            onClick={() => addProductToCart(1)}
                            disabled={!isAvailable || !isActive || isLoadingCartRequest || isBoxDeliveryAllowed}>
                            {orderBtnTitle}
                          </button>
                        )}
                      </div>
                    </div>
                    {closeModal && (
                      <button
                        className="mobile-button"
                        onClick={() => {
                          closeModal();
                          toggleCart();
                        }}>
                        <span className="icon icon-cart">
                          {count ? <span className="number">{count || ''}</span> : ''}
                        </span>
                        <span className="text">{sum ? sum.toFixed(2) : 0}€</span>
                      </button>
                    )}
                  </div>
                </>
              )}
              {!isMyBioShop && (
                <>
                  <ul className="product-price">
                    {salePrice !== null ? (
                      <>
                        <li className="price">
                          <div className="product-price_sale">
                            <div className="number-sale">
                              {formatPrice(salePriceWithoutVAT)}
                              {formattedWightableUnit}
                            </div>
                            <div className="number">
                              {formatPrice(priceWithoutVAT)}
                              {formattedWightableUnit}
                            </div>
                          </div>
                        </li>
                        <div className="item-food_price">
                          <div className="packaging">
                            <span className="number-unit">
                              inkl. MwSt.{' '}
                              {!isWeightable ? (
                                <span>
                                  {formatPrice(+salePrice, false)} €
                                  <br />
                                </span>
                              ) : null}
                              {isWeightable
                                ? `${formatPrice(+salePrice, false)} €/${formattedUnitType}`
                                : `${formatPrice(
                                    calculateFullPrice(+salePriceWithoutVAT, quantityPerPack, unit.type),
                                    false,
                                  )} €/${formattedUnitType}`}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <li className="price">
                          {formatPrice(priceWithoutVAT)}
                          {formattedWightableUnit}
                        </li>
                        <div className="item-food_price">
                          <div className="packaging">
                            <span className="number-unit">
                              inkl. MwSt.{' '}
                              {!isWeightable ? (
                                <span>
                                  {formatPrice(+price, false)} €
                                  <br />
                                </span>
                              ) : null}
                              {isWeightable
                                ? `${formatPrice(+price, false)} €/${formattedUnitType}`
                                : `${formatPrice(
                                    calculateFullPrice(priceWithoutVAT, quantityPerPack, unit.type),
                                    false,
                                  )} €/${formattedUnitType}`}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </ul>
                  <PriceRangeTable
                    price={salePriceWithoutVAT || priceWithoutVAT}
                    productPriceRanges={productPriceRangesWithoutVAT}
                  />
                  <div className="product-button">
                    <div className="buttons">
                      <div className="buttons-div">
                        {closeModal && (
                          <button className="btn btn-close_mobile" type="button" onClick={() => closeModal()}></button>
                        )}
                        {productCart && isActive ? (
                          <CartCountItem item={productCart} />
                        ) : (
                          <button
                            className="btn btn-blue"
                            type="submit"
                            onClick={() => addProductToCart(1)}
                            disabled={!isAvailable || !isActive || isBoxDeliveryAllowed}>
                            {orderBtnTitle}
                          </button>
                        )}
                      </div>
                    </div>
                    {closeModal && (
                      <button
                        className="mobile-button"
                        onClick={() => {
                          closeModal();
                          toggleCart();
                        }}>
                        <span className="icon icon-cart">
                          {count ? <span className="number">{count || ''}</span> : ''}
                        </span>
                        <span className="text">{sum ? sum.toFixed(2) : 0}€</span>
                      </button>
                    )}
                  </div>
                </>
              )}
            </>
          )}
          {!isLess991 && (
            <article className="product-content product-content_shop">
              {isMyBioShop && (
                <div className="product-content_block">
                  <ul className="product-price">
                    <li>
                      <span className="available yes">{buildLocale(currentTranslate, 'commonAvailable')}</span>
                    </li>
                    {salePrice !== null ? (
                      <li className="price">
                        <div className="product-price_sale">
                          <span className="number">
                            {formatPrice(price * 1)}
                            {formattedWightableUnit}
                          </span>
                          <div className="number-sale">
                            {formatPrice(+salePrice * 1)}
                            {formattedWightableUnit}
                          </div>
                        </div>
                      </li>
                    ) : (
                      <li className="price">
                        {formatPrice(price * 1)}
                        {formattedWightableUnit}
                      </li>
                    )}
                  </ul>
                  {product.unit && (
                    <UnitPrice
                      price={price}
                      salePrice={salePrice}
                      unit={product.unit}
                      quantityPerPack={product.quantityPerPack}
                      isWeightable={product.isWeightable}
                    />
                  )}
                  {!taxPercent ? null : (
                    <>
                      <p className="shipment-tax">
                        {buildLocale(currentTranslate, 'shipmentTitle1')} {taxPercent}%{' '}
                        {buildLocale(currentTranslate, 'shipmentTitle2')}{' '}
                        <span
                          onClick={() => {
                            setIsOpenShipmentModal(true);
                          }}>
                          {buildLocale(currentTranslate, 'shipmentLink')}
                        </span>
                      </p>
                    </>
                  )}
                  {isOpenShipmentModal && (
                    <ShipmentModal isOpen={isOpenShipmentModal} closeModal={() => setIsOpenShipmentModal(false)} />
                  )}
                  <PriceRangeTable price={salePrice || price} productPriceRanges={productPriceRanges} />
                  {productCart && isActive ? (
                    <CartCountItem item={productCart} />
                  ) : (
                    <button
                      className="btn btn-blue"
                      type="submit"
                      onClick={() => addProductToCart(1)}
                      disabled={!isAvailable || !isActive || isBoxDeliveryAllowed}>
                      {orderBtnTitle}
                    </button>
                  )}
                </div>
              )}
              {!isMyBioShop && (
                <div className="product-content_block">
                  <ul className="product-price">
                    <li>
                      <span className="available yes">{buildLocale(currentTranslate, 'commonAvailable')}</span>
                    </li>
                    {salePrice !== null ? (
                      <>
                        <li className="price">
                          <div className="product-price_sale">
                            <div className="number-sale">
                              {formatPrice(salePriceWithoutVAT)}
                              {formattedWightableUnit}
                            </div>
                            <div className="number">
                              {formatPrice(priceWithoutVAT)}
                              {formattedWightableUnit}
                            </div>
                          </div>
                        </li>
                        <div className="item-food_price">
                          <div className="packaging">
                            <span className="number-unit">
                              inkl. MwSt.{' '}
                              {!isWeightable ? (
                                <span>
                                  {formatPrice(+salePrice, false)} €
                                  <br />
                                </span>
                              ) : null}
                              {isWeightable
                                ? `${formatPrice(+salePrice, false)} €/${formattedUnitType}`
                                : `${formatPrice(
                                    calculateFullPrice(+salePriceWithoutVAT, quantityPerPack, unit.type),
                                    false,
                                  )} €/${formattedUnitType}`}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <li className="price">
                          {formatPrice(priceWithoutVAT)}
                          {formattedWightableUnit}
                        </li>
                        <div className="item-food_price">
                          <div className="packaging">
                            <span className="number-unit">
                              inkl. MwSt.{' '}
                              {!isWeightable ? (
                                <span>
                                  {formatPrice(+price, false)} €
                                  <br />
                                </span>
                              ) : null}
                              {isWeightable
                                ? `${formatPrice(+price, false)} €/${formattedUnitType}`
                                : `${formatPrice(
                                    calculateFullPrice(priceWithoutVAT, quantityPerPack, unit.type),
                                    false,
                                  )} €/${formattedUnitType}`}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </ul>
                  <PriceRangeTable
                    price={salePriceWithoutVAT || priceWithoutVAT}
                    productPriceRanges={productPriceRangesWithoutVAT}
                  />
                  {productCart && isActive ? (
                    <CartCountItem item={productCart} />
                  ) : (
                    <button
                      className="btn btn-blue"
                      disabled={!isAvailable || !isActive || isBoxDeliveryAllowed}
                      type="submit"
                      onClick={() => addProductToCart(1)}>
                      {orderBtnTitle}
                    </button>
                  )}
                </div>
              )}
            </article>
          )}
        </>
      )}
      {!isAvailable && (
        <ul className="item-food_price">
          <li className="price">
            <span className="info-center">{buildLocale(currentTranslate, 'runOutProduct')}</span>
          </li>
          {!!categoryIdForProduct && (
            <li>
              <Link to={getCategoryLink()}>{buildLocale(currentTranslate, 'productShowSimilar')} &raquo;</Link>
            </li>
          )}
          <li className="subscription-message">
            {isSubscriptionMessageLoaded && (
              <>
                {!!productSubscriptionForProduct && (
                  <>
                    <img src={bellFillIcon} alt="" width="16" height="16" />
                    <span className="btn-more" onClick={tryToUnsubscribe}>
                      {buildLocale(currentTranslate, 'productAvailabilityDeactivateSubscription')}
                    </span>
                  </>
                )}
                {!productSubscriptionForProduct && (
                  <>
                    <img src={bellIcon} alt="" width="16" height="16" />
                    <span className="btn-more" onClick={tryToSubscribe}>
                      {buildLocale(currentTranslate, 'productAvailabilityInformMeByEmail')}
                    </span>
                  </>
                )}
              </>
            )}
          </li>
        </ul>
      )}
    </>
  );
};

export default PriceBlock;
