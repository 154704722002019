import React from 'react';
import { IProduct } from '../../typings/IProduct';
import { Product, QuantitativeValue, UnitPriceSpecification, WithContext } from 'schema-dts';
import { JsonLd } from 'react-schemaorg';
import { usePaths } from '../Routes/RouterList';
import config from '../../config';
import { clearHtmlTags } from '../../utils/clearHtmlTags';
import { calculateWeightablePriceWithCount } from '../../utils/calculateUnits';
import { productUnitsAlias } from '../../constants/productUnitsAlias';
import { productUnits } from '../../constants/productUnits';

interface IProductJsonLdProps {
  product?: IProduct;
}

const ProductJsonLd = ({ product }: IProductJsonLdProps) => {
  const paths = usePaths();

  const getUnitForValueReference = (unit: productUnitsAlias) => {
    if (unit === productUnitsAlias.ml) {
      return productUnitsAlias.l;
    }

    if (unit === productUnitsAlias.g) {
      return productUnitsAlias.kg;
    }

    return unit;
  };

  const makeReferenceQuantityData = (product: IProduct): QuantitativeValue => {
    const unitAlias = productUnitsAlias[productUnits[product.unit.id]];
    const quantityPerPack = Number(product.quantityPerPack);
    return {
      '@type': 'QuantitativeValue',
      value: quantityPerPack,
      unitCode: unitAlias,
      valueReference: {
        '@type': 'QuantitativeValue',
        value: 1,
        unitCode: getUnitForValueReference(unitAlias),
      },
    };
  };
  const extendPriceDataWithReferenceQuantity = (
    item: UnitPriceSpecification,
    product: IProduct,
    price: string,
  ): void => {
    item.referenceQuantity = makeReferenceQuantityData(product);
    item.price = Number(
      calculateWeightablePriceWithCount(Number(price), product.unit.type, '1', product.quantityPerPack).toFixed(2),
    );
  };

  const makePriceSpecificationData = (product: IProduct): UnitPriceSpecification[] => {
    const currentProductOption = product?.productOptions?.[0];
    const data: UnitPriceSpecification[] = [];
    if (currentProductOption?.salePrice) {
      const item = {
        '@type': 'UnitPriceSpecification',
        priceType: 'https://schema.org/SalePrice',
        price: Number(currentProductOption.salePrice),
        priceCurrency: 'EUR',
      } as UnitPriceSpecification;

      if (product.isWeightable) {
        extendPriceDataWithReferenceQuantity(item, product, currentProductOption.salePrice);
      }
      data.push(item);
    }

    if (currentProductOption?.price) {
      const item = {
        '@type': 'UnitPriceSpecification',
        priceType: 'https://schema.org/ListPrice',
        price: Number(currentProductOption.price),
        priceCurrency: 'EUR',
      } as UnitPriceSpecification;

      if (product.isWeightable) {
        extendPriceDataWithReferenceQuantity(item, product, currentProductOption.price);
      }
      data.push(item);
    }

    return data;
  };

  const generateSchemaOrgJson = (product: IProduct): WithContext<Product> => {
    const schema: WithContext<Product> = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      sku: product.externalId,
      gtin: product.gtinArticle,
      name: product.title,
      category: product.customCategories?.[0]?.name,
      image: product?.cdnImages?.map((img) => img.variants.public),
      description: clearHtmlTags(product.shortDescription),
      offers: {
        '@type': 'Offer',
        url: config.canLink + paths.product(product.id),
        itemCondition: 'https://schema.org/NewCondition',
        availability: !!product?.productOptions?.[0]?.availableAmount
          ? 'https://schema.org/InStock'
          : 'https://schema.org/OutOfStock',
        priceSpecification: makePriceSpecificationData(product),
      },
    };

    if (product.brand) {
      schema.brand = {
        '@type': 'Brand',
        name: product.brand.nameDe,
      };
    }

    if (product.sizeArticle) {
      const [width, depth, height] = product.sizeArticle.split(' x ');
      if (Number(depth)) {
        schema.depth = {
          '@type': 'QuantitativeValue',
          value: Number(depth),
          unitCode: 'CMT',
        };
      }
      if (Number(height)) {
        schema.height = {
          '@type': 'QuantitativeValue',
          value: Number(height),
          unitCode: 'CMT',
        };
      }
      if (Number(width)) {
        schema.width = {
          '@type': 'QuantitativeValue',
          value: Number(width),
          unitCode: 'CMT',
        };
      }
    }
    if (Number(product.weight)) {
      schema.weight = {
        '@type': 'QuantitativeValue',
        value: Number(product.weight),
        unitText: product.weightUnit?.typeDe,
      };
    }
    if (product.reviewsModeratedCount) {
      schema.aggregateRating = {
        '@type': 'AggregateRating',
        ratingValue: Number(product.reviewsAverageRating),
        ratingCount: product.reviewsModeratedCount,
      };
    }
    return schema;
  };

  if (!product) {
    return null;
  }
  return <JsonLd item={generateSchemaOrgJson(product)} />;
};

export default ProductJsonLd;
