import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionsCart, selectorsCart } from '../../redux/cart/cartReducer';
import CartItem from './CartItem';
import { useFormattingContext } from '../../context/FormattingContext';
import { selectorsOrder } from '../../redux/order/orderReducer';
import { actionsUser } from '../../redux/user/userReducer';
import { useLocation } from 'react-router-dom';
import { isBrowser } from '../../utils/isBrowser';
import { ID_UNIT_WEIGHT } from '../../constants/constantsId';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import service from '../../services/service';
import { useLessThen991 } from '../../utils/mediaQuery';
import ClipLoader from 'react-spinners/ClipLoader';
import { blockBodyScroll } from '../../utils/blockBodyScroll';
import { setLoaderColor } from '../../utils/setLoaderColor';
import { actionsOther, selectorsOther } from '../../redux/other/otherReducer';
import ShipmentModal from '../Modals/ShipmentModal';

interface IProps {
  sumWithCoupon?: number | null;
  isHeader?: boolean;
  isLowerThenMin?: boolean;
  minPrice?: number;
  isOpenCart?: boolean;
}

const Cart = React.memo((props: IProps) => {
  const { sumWithCoupon, isHeader, isLowerThenMin, minPrice, isOpenCart } = props;
  const location = useLocation();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const cart = useSelector(selectorsCart.getCart);
  const dispatch = useDispatch<any>();
  const { formatPrice } = useFormattingContext();
  const isEditable = useSelector(selectorsOrder.getIsEditable);
  const sum = useSelector(selectorsCart.getGeneralSum);
  const idSellPoint = useSelector(selectorsCart.getIdSellPoint);
  const items = useSelector(selectorsCart.getCartDataProducts);
  const notAvailableProducts = useSelector(selectorsCart.getNotAvailableProducts);
  const isLoadingData = useSelector(selectorsCart.isLoadingData);
  const freeDeliveryOrderAmount = useSelector(selectorsOther.getFreeDeliveryOrderAmount);
  const [snapShot, setSnapshot] = useState({
    sum: 0,
    items: [] as any,
    idSellPoint: null as null | number,
  });
  const cartItems = items && items.length > 0 ? items : snapShot.items;
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLess991 = useLessThen991(isDesktop);
  const bottleTotalDeposit = cart?.bottleTotalDeposit ? +cart.bottleTotalDeposit : 0;
  const rackTotalDeposit = cart?.rackTotalDeposit ? +cart.rackTotalDeposit : 0;
  const totalWeightDeposit = cart?.totalWeightDeposit ? +cart.totalWeightDeposit : 0;
  const [isOpenShipmentModal, setIsOpenShipmentModal] = useState(false);

  const getTitleBlockSize = (title: string) => {
    if (isBrowser) {
      const infoBlockItems = document.getElementsByClassName('title-name-cart');
      if (infoBlockItems && infoBlockItems.length > 0) {
        for (let i = 0; i < infoBlockItems.length; i++) {
          const slicedTitle = Math.round(infoBlockItems[i].clientWidth / 6.85 - 4);
          return slicedTitle < title.length ? `${title.slice(0, slicedTitle)}...` : title;
        }
      }
    }
    return title;
  };

  useEffect(() => {
    if (window && window.screen.width < 991 && isOpenCart) {
      blockBodyScroll(location && !location.pathname.includes('basket'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenCart]);

  useEffect(() => {
    if (freeDeliveryOrderAmount === null) {
      service
        .calculateDeliveryPrice(1, '1')
        .then((value: any) => {
          dispatch(actionsOther.setFreeDeliveryOrderAmount(value.data.freeDelivery.amountFrom));
        })
        .catch((e) => console.log(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEditable) {
      setSnapshot({
        sum,
        items,
        idSellPoint,
      });
      dispatch(actionsCart.clear());
      dispatch(actionsUser.setError(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditable]);

  const isWeightUnit = useMemo(() => {
    return items && items.some((el) => el.product.units === ID_UNIT_WEIGHT);
  }, [items]);

  if (isLess991 && isOpenCart === false) {
    return null;
  }

  return (
    <div>
      {isLoadingData && (
        <div className="pay-loader">
          <ClipLoader size={30} color={setLoaderColor()} loading={true} />
        </div>
      )}
      {!isHeader && <p className="basket-block_time">{buildLocale(currentTranslate, 'tenMinMessage')}</p>}
      <div className="basket-block_scroll scrollbar-inner">
        {cartItems.length > 0 &&
          cartItems.map((item) => {
            const isNotAvailable =
              notAvailableProducts && notAvailableProducts.find((p) => p.product.id === item.product.id);

            const setAvailableType = () => {
              if (!isNotAvailable) {
                return false;
              } else if (isNotAvailable && isNotAvailable.count === 1) {
                return 'noProduct';
              } else {
                return 'count';
              }
            };
            return (
              <CartItem
                key={item.product.id}
                item={item}
                defaultSellPoint={snapShot.idSellPoint}
                notAvailableType={setAvailableType()}
                getTitleBlockSize={getTitleBlockSize}
              />
            );
          })}
        {isWeightUnit && isBrowser && (
          <div className="basket-content_add red-frame red-frame_basket">
            <p className="caption">{buildLocale(currentTranslate, 'commonAttanion')}</p>
            {buildLocale(currentTranslate, 'commonTextWeight')}
          </div>
        )}
      </div>
      <div className="basket-block_bottom">
        {isLowerThenMin && minPrice && (
          <span className="low-price-error">
            {buildLocale(currentTranslate, 'toLowOrderSum')} {formatPrice(minPrice)}
          </span>
        )}
        {bottleTotalDeposit > 0 && (
          <p className="basket-block_bottle">
            <span>{buildLocale(currentTranslate, 'cartTotalBottleDeposit')}</span>
            <span>{formatPrice(bottleTotalDeposit)}</span>
          </p>
        )}
        {rackTotalDeposit > 0 && (
          <p className="basket-block_bottle">
            <span>{buildLocale(currentTranslate, 'cartTotalRackDeposit')}</span>
            <span>{formatPrice(rackTotalDeposit)}</span>
          </p>
        )}
        {/* {freeDeliveryOrderAmount && (
          <div className="basket-block_maxdev">
            {buildLocale(currentTranslate, 'freePriceText1')} {formatPrice(freeDeliveryOrderAmount)}
            {buildLocale(currentTranslate, 'freePriceText2')}
          </div>
        )} */}
        <p
          className="basket-block_delivery shipment"
          onClick={() => {
            setIsOpenShipmentModal(true);
          }}>
          <span>{buildLocale(currentTranslate, 'btnDelivery')}</span>
          <span>{formatPrice(cart?.deliveryCost)}</span>
        </p>
        {isOpenShipmentModal && (
          <ShipmentModal isOpen={isOpenShipmentModal} closeModal={() => setIsOpenShipmentModal(false)} />
        )}
        {totalWeightDeposit > 0 && (
          <p className="basket-block_delivery">
            <span>{buildLocale(currentTranslate, 'cartTotalWeightDeposit')}</span>
            <span>{formatPrice(totalWeightDeposit)}</span>
          </p>
        )}
        <p className="basket-block_price">
          <span className="caption">{buildLocale(currentTranslate, 'cartSum')}</span>
          <span className="total-price">
            <span className={`number ${sumWithCoupon ? 'old' : ''}`}>{formatPrice(cart.total)}</span>
          </span>
        </p>
      </div>
    </div>
  );
});

export default Cart;
